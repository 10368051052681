<template>
  <div>
    <div class="amap">
      <amap
        :animateEnable="false"
        @complete="onComplete"
        @moveend="onMoveEnd"
        cache-key="home-map"
        map-style="amap://styles/whitesmoke"
        @click="onSetupSelectedMark"
        :center.sync="center"
        :zoom.sync="zoom"
      >
        <amap-satellite-layer :opacity="1" v-if="satellite" />
        <div>
          <amap-marker
            :icon="item.icon"
            :offset="[-24, -38]"
            :position="[item.longitude, item.latitude]"
            v-for="item in pointList"
            :key="'market-' + item.id"
            :zooms="[6.5, 20]"
            @click="clickPoint(item)"
          >
            <div class="market">
              <div class="marker-label">
                <span :class="'t' + item.type">{{ getMarkerTitle(item) }}</span>
              </div>
              <img
                v-if="item.type === 1"
                style="width: 36px; height: 36px"
                src="../assets/image/map-marker-radius1.png"
              />
              <img
                v-else-if="item.type === 2"
                style="width: 36px; height: 36px"
                src="../assets/image/map-marker-radius2.png"
              />
              <img
                v-else-if="item.type === 3"
                style="width: 36px; height: 36px"
                src="../assets/image/map-marker-radius3.png"
              />
              <img
                v-else-if="item.type === 4"
                style="width: 36px; height: 36px"
                src="../assets/image/map-marker-radius4.png"
              />
            </div>
          </amap-marker>
        </div>
        <!-- <amap-satellite-layer /> -->
        <amap-marker :position="[selectMarket.lng, selectMarket.lat]" />
      </amap>
    </div>
    <div class="tool-bar">
      <div class="left card">
        <template v-if="!toolbar.leftExt">
          <img
            class="icon-btn"
            @click="showLeft()"
            src="/image/icon_right.png"
          />
        </template>
        <template v-if="toolbar.leftExt">
          <span class="title">点位展示</span>
          <span class="label">项目年份</span>
          <div class="value">
            <el-date-picker
              clearable
              v-model="query.common_year"
              type="year"
              placeholder="选择年"
            >
            </el-date-picker>
          </div>
          <span class="label">对象类型</span>
          <div class="value">
            <el-select
              v-model="query.common_obj_type"
              clearable
              multiple
              :collapse-tags="true"
            >
              <el-option
                v-for="item in OBJ_TYPE_LIST"
                :key="'obj-type-item-' + item.value"
                :value="item.value"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>

          <span class="label">关键词搜索</span>
          <div class="value">
            <el-input
              placeholder="请输入关键词查询项目点位"
              v-model="query.common_name"
              clearable
            ></el-input>
          </div>

          <el-button
            style="margin-left: 32px"
            type="primary"
            icon="el-icon-search"
            @click="search()"
            v-loading="loading"
            >查询</el-button
          >
          <el-button type="primary" icon="el-icon-refresh-left" @click="reset()"
            >重置</el-button
          >
          <img
            style="margin-left: 32px"
            class="icon-btn"
            @click="hideLeft()"
            src="/image/icon_left.png"
          />
        </template>
      </div>

      <div class="right card">
        <template v-if="!toolbar.rightExt">
          <img
            class="icon-btn"
            @click="showRight()"
            src="/image/icon_left.png"
          />
        </template>
        <template v-if="toolbar.rightExt">
          <img
            style="margin-right: 16px"
            class="icon-btn"
            @click="hideRight()"
            src="/image/icon_right.png"
          />
          <el-checkbox border v-model="satellite" style="margin-right: 8px"
            >卫星地图</el-checkbox
          >
          <el-button
            v-if="role.role_code !== 'usero'"
            type="success"
            @click="addPoint()"
            icon="el-icon-plus"
            >新增项目点位</el-button
          >
          <el-button
            v-if="role.role_code === 'admin'"
            type="success"
            @click="addExport()"
            icon="el-icon-download"
            >导出项目</el-button
          >

          <el-dropdown style="margin-left: 16px">
            <el-avatar
              :size="54"
              class="avatar"
              src="/image/user_avatar.png"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown" @command="handleCommand">
              <el-dropdown-item
                command="userManager"
                v-if="role.role_code === 'admin'"
              >
                <span @click="onClickUserManager()">账号管理</span>
              </el-dropdown-item>
              <el-dropdown-item command="modifyPassword">
                <span @click="onClickModiyPwd()">密码修改</span>
              </el-dropdown-item>
              <el-dropdown-item command="logout"
                ><span @click="logout()">退出登录</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </div>
    </div>
    <point-upsert-dialog
      @onChange="search()"
      :show.sync="showAdd"
      :point="selectMarket"
    ></point-upsert-dialog>
    <point-detail-dialog
      @onChange="search()"
      :show.sync="showDetail"
      :detail="detail"
    ></point-detail-dialog>
    <point-export-dialog :show.sync="showExport"></point-export-dialog>
    <modity-password-dialog :show.sync="showModifyPwd"></modity-password-dialog>
    <user-list-dialog :show.sync="showUserManager"></user-list-dialog>
  </div>
</template>

<script>
import { OBJ_TYPE_LIST } from "@/config/enum";
import PointExportDialog from "./components/PointExportDialog.vue";
import PointUpsertDialog from "./components/PointUpsertDialog.vue";
import PointDetailDialog from "./components/PointDetailDialog.vue";
import ModityPasswordDialog from "./components/ModityPasswordDialog.vue";
import UserListDialog from "./components/UserListDialog.vue";
const queryTemp = {
  common_year: undefined,
  common_obj_type: undefined,
  common_name: undefined,
};
export default {
  components: {
    PointExportDialog,
    PointUpsertDialog,
    PointDetailDialog,
    ModityPasswordDialog,
    UserListDialog,
  },
  name: "HomeView",
  data() {
    return {
      satellite: true,
      empty: "1",
      loading: false,
      OBJ_TYPE_LIST,
      query: JSON.parse(JSON.stringify(queryTemp)),
      showAdd: false,
      showDetail: false,
      showExport: false,
      showModifyPwd: false,
      showUserManager: false,
      detail: undefined,
      zoom: 15,
      center: [120.761421, 30.747531],
      centerPoint: {
        lat: -1,
        lng: -1,
      },
      selectMarket: {
        lat: -1,
        lng: -1,
      },
      pointList: [],
      toolbar: {
        leftExt: true,
        rightExt: true,
      },
    };
  },
  computed: {
    radius() {
      let scale = 20 - this.zoom;
      scale = parseInt(Math.pow(2, scale));
      return 140 * scale;
    },
  },
  async created() {
    await this.search();
  },
  methods: {
    getMarkerIcon(item) {
      let image = "";
      if (item) {
        switch (item.type) {
          case 1: {
            image = require("../assets/image/map-marker-radius1.png");
            break;
          }
          case 2: {
            image = require("../assets/image/map-marker-radius2.png");
            break;
          }
          case 3: {
            image = require("../assets/image/map-marker-radius3.png");
            break;
          }
          case 4: {
            image = require("../assets/image/map-marker-radius4.png");
            break;
          }
        }
      }
      console.log(item.type);
      return {
        image: image,
        imageSize: [48, 48],
      };
    },
    getMarkerTitle(item) {
      let title = "";
      switch (item.type) {
        case 1: {
          title = item.project_code;
          break;
        }
        case 2: {
          title = item.court_book;
          break;
        }
        case 3: {
          title = item.ex_name;
          break;
        }
        case 4: {
          title = item.project_name;
          break;
        }
      }
      return title;
    },
    async onComplete(target) {
      const center = target.options.center;
      this.center = center;
      this.centerPoint = {
        lng: center[0],
        lat: center[1],
      };
      await this.search();
    },
    async onMoveEnd(event) {
      const center = event.target.getCenter();
      this.centerPoint = {
        lng: center.lng,
        lat: center.lat,
      };
      await this.search();
    },
    async search() {
      // if (this.zoom <= 12) {
      //   this.pointList = [];
      //   return;
      // }
      const query = JSON.parse(JSON.stringify(this.query));
      if (query.common_name) {
        query.common_name = query.common_name.trim();
      }
      try {
        this.loading = true;
        const res = await this.$api.post("/api/project/list", {
          ...this.centerPoint,
          distance: this.radius,
          ...query,
          pageNo: 1,
          pageSize: 500,
        });
        this.pointList = res.rows.map((item) => {
          return {
            ...item,
            icon: this.getMarkerIcon(item),
          };
        });
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    async reset() {
      this.query = JSON.parse(JSON.stringify(queryTemp));
      await this.search();
    },
    async addPoint() {
      if (
        !this.selectMarket ||
        this.selectMarket.lat == -1 ||
        this.selectMarket.lng == -1
      ) {
        this.$message.warning("请先选择点位");
        return;
      }
      this.showAdd = true;
    },
    async addExport() {
      this.showExport = true;
    },
    onSetupSelectedMark({ lnglat }) {
      this.selectMarket = {
        lat: lnglat.lat,
        lng: lnglat.lng,
      };
      // this.search();
    },
    hideLeft() {
      this.toolbar.leftExt = false;
    },
    showLeft() {
      this.toolbar.leftExt = true;
    },
    hideRight() {
      this.toolbar.rightExt = false;
    },
    showRight() {
      this.toolbar.rightExt = true;
    },
    clickPoint(point) {
      this.detail = point;
      this.showDetail = true;
    },
    onClickModiyPwd() {
      this.showModifyPwd = true;
    },
    onClickUserManager() {
      this.showUserManager = true;
    },
    async logout() {
      try {
        await this.$api.post("/api/user/logout");
      } catch (error) {
        console.error(error.message);
      } finally {
        await this.$store.dispatch("session");
      }
    },
    async handleCommand(cmd) {
      switch (cmd) {
        case "userManager": {
          await this.onClickUserManager();
          break;
        }
        case "modifyPassword": {
          await this.onClickModiyPwd();
          break;
        }
        case "logout": {
          await this.logout();
          break;
        }
      }
    },
  },
};
</script>

<style scoped>
.amap {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
}
.tool-bar {
  position: fixed;
  top: 32px;
  left: 32px;
  right: 32px;
}

.tool-bar .card {
  border-radius: 124px;
  scale: 0.8;
  box-shadow: 5px 5px 8px #444444;
}
.tool-bar .left {
  transform-origin: 0 0;
  align-items: center;
  height: 72px;
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  background: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
}

.tool-bar .left * {
  display: inline-block;
}

.tool-bar .left .icon-btn {
  display: inline-flex;
}

.tool-bar .left .label {
  margin-left: 16px;
  margin-right: 8px;
}
.tool-bar .left .value {
  width: 220px;
}

.tool-bar .left .title {
  font-size: 28px;
  font-weight: bold;
}
.tool-bar .left .el-form {
  display: inline;
}

.tool-bar .right {
  transform-origin: 100% 0;
  align-items: center;
  height: 72px;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  top: 0;
  background: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
}

.tool-bar .right .icon-btn {
  display: inline-flex;
}

.tool-bar .el-form-item {
  margin-bottom: 0px;
}
.icon-btn {
  display: flex;
  align-items: center;
  text-align: center;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon {
  font-size: 28px;
  color: rgb(255, 255, 255);
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.avatar {
  cursor: pointer;
  background: white;
}
</style>
<style>
/* .amap-marker-label {
  color: white;
  border: #ff9900 solid 1px;
  border-radius: 4px;
  box-shadow: 0px 2px 2px 0px #ff9900;
  font-size: 14px;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  background: #ff9900;
} */
.marker-label {
  left: 18px;
  top: -30px;
  position: absolute;
}
.marker-label span {
  display: inline-block;
  position: absolute;
  color: white;
  border: #ff9900 solid 1px;
  border-radius: 4px;
  box-shadow: 0px 2px 2px 0px #ff9900;
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  z-index: 2;
  background: #ff9900;
  transform: translateX(-50%);
  white-space: nowrap;
}
.marker-label span.t1 {
  border-color: #f4b929;
  background-color: #f4b929;
  box-shadow: 0px 2px 2px 0px #f4b929;
}

.marker-label span.t2 {
  border-color: #d81e06;
  background-color: #d81e06;
  box-shadow: 0px 2px 2px 0px #d81e06;
}

.marker-label span.t3 {
  border-color: #1296db;
  background-color: #1296db;
  box-shadow: 0px 2px 2px 0px #1296db;
}

.marker-label span.t4 {
  border-color: #13d954;
  background-color: #13d954;
  box-shadow: 0px 2px 2px 0px #13d954;
}

.tool-bar .el-card__body {
  display: flex;
}
.market {
}
</style>
