import { mapState } from 'vuex'
import dayjs from 'dayjs';
import {
    OBJ_TYPE_LIST,
    EX_TYPE_LIST,
    EL_ORG_LIST,
    POINT_TYPE_LIST,
    EL_COURT_LIST,
    EL_CLIENT
} from "@/config/enum";

export default {
    data() {
        return {
            OBJ_TYPE_LIST,
            EX_TYPE_LIST,
            EL_ORG_LIST,
            POINT_TYPE_LIST,
            EL_COURT_LIST,
            EL_CLIENT
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            role: state => state.role
        }),

    },
    methods: {
        formatEnum(value, list) {
            const item = list.find(item => item.value === value)
            if (item) {
                return item.name;
            } else {
                return value
            }
        },
        formatData(date, format) {
            if (!date) {
                return "";
            }
            if (format) {
                return dayjs(date).format(format);
            } else {
                return dayjs(date).format("YYYY-MM-DD");
            }
        },
        formatDataTime(date, format) {
            if (!date) {
                return "";
            }
            if (format) {
                return dayjs(date).format(format);
            } else {
                return dayjs(date).format("YYYY-MM-DD HH-mm:ss");
            }
        },
        urlFileName(url) {
            if (!url) {
                return "";
            } else {
                const list = url.split("/")
                if (list.length >= 0) {
                    return list[list.length - 1];
                } else {
                    return "";
                }
            }
        },
        getFileList(files) {
            if (files) {
                return files.split(",").map((item) => {
                    const index = item.lastIndexOf("/");
                    return {
                        name: item.substring(index + 1, item.length),
                        url: process.env.VUE_APP_API_BASE_URL + item,
                    };
                });
            } else {
                return [];
            }
        },
    }
}