import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import api from './util/request'
import './assets/css/app.css'
import AmapVue from '@amap/amap-vue';
import app from './mixin/app';
import store from './store';
import JsonExcel from "vue-json-excel";
 
Vue.component("downloadExcel", JsonExcel);

AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = 'e5436437fac8cee749ef9f5776272415';
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);
console.log(process.env.VUE_APP_API_BASE_URL)

Vue.config.productionTip = false

Vue.use(ElementUI);

Vue.prototype.$api = api
Vue.prototype.$store = store

Vue.mixin(app)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
