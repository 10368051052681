export const POINT_TYPE_LIST = [{
    name: "常规评估类",
    value: 1
}, {
    name: "司法拍卖类",
    value: 2
}, {
    name: "案例类",
    value: 3
}, {
    name: "其他项目类",
    value: 4
}]

export const OBJ_TYPE_LIST = [{
    name: "住宅",
    value: 1
}, {
    name: "商业",
    value: 2
}, {
    name: "工业",
    value: 3
}, {
    name: "办公",
    value: 4
}, {
    name: "土地",
    value: 5
}, {
    name: "在建工程",
    value: 6
}]

export const EX_TYPE_LIST = [{
    name: "住宅",
    value: 1
}, {
    name: "商业",
    value: 2
}, {
    name: "工业",
    value: 3
}, {
    name: "办公",
    value: 4
}, {
    name: "土地",
    value: 5
}]


export const EL_CLIENT = [{
    name: "华夏银行股份有限公司",
    value: "华夏银行股份有限公司"
}, {
    name: "嘉兴银行股份有限公司",
    value: "嘉兴银行股份有限公司"
}, {
    name: "浙江民泰商业银行股份有限公司",
    value: "浙江民泰商业银行股份有限公司"
}, {
    name: "上海浦东发展银行股份有限公司",
    value: "上海浦东发展银行股份有限公司"
}, {
    name: "平安银行股份有限公司",
    value: "平安银行股份有限公司"
}, {
    name: "中国邮政储蓄银行",
    value: "中国邮政储蓄银行"
}, {
    name: "北京银行股份有限公司",
    value: "北京银行股份有限公司"
}, {
    name: "湖州银行股份有限公司",
    value: "湖州银行股份有限公司"
}, {
    name: "中国银行股份有限公司",
    value: "中国银行股份有限公司"
}, {
    name: "中国工商银行股份有限公司",
    value: "中国工商银行股份有限公司"
}, {
    name: "中国农业银行股份有限公司",
    value: "中国农业银行股份有限公司"
}, {
    name: "浙江稠州商业银行股份有限公司",
    value: "浙江稠州商业银行股份有限公司"
}, {
    name: "广发银行股份有限公司",
    value: "广发银行股份有限公司"
}, {
    name: "宁波银行股份有限公司",
    value: "宁波银行股份有限公司"
}, {
    name: "中国民生银行股份有限公司",
    value: "中国民生银行股份有限公司"
}, {
    name: "兴业银行股份有限公司",
    value: "兴业银行股份有限公司"
}, {
    name: "招商银行股份有限公司",
    value: "招商银行股份有限公司"
}, {
    name: "浙商银行",
    value: "浙商银行"
}, {
    name: "农商行",
    value: "农商行"
}, {
    name: "其他银行",
    value: "其他银行"
}, {
    name: "社会金融机构",
    value: "社会金融机构"
}, {
    name: "非金融机构客户",
    value: "非金融机构客户"
}, {
    name: "政府机构",
    value: "政府机构"
}
]

export const EL_ORG_LIST = [{
    name: "浙江利达土地房地产评估咨询有限公司",
    value: 2
}, {
    name: "杭州中立房地产土地评估规划咨询有限公司",
    value: 3
}, {
    name: "其他",
    value: 4
}]


export const EL_COURT_LIST = [{
    name: "嘉兴地区法院",
    value: 1
}, {
    name: "浙江省内法院",
    value: 2
}, {
    name: "省外法院",
    value: 3
}, {
    name: "高院",
    value: 4
}, {
    name: "其他",
    value: 5
}]