<template>
  <el-dialog
    title="项目信息导出"
    :close-on-click-modal="false"
    :visible.sync="visable"
    @closed="reset()"
    width="1500px"
  >
    <el-tabs v-model="form.type">
      <el-tab-pane label="常规评估类" name="1"> </el-tab-pane>
      <el-tab-pane label="司法拍卖类" name="2"> </el-tab-pane>
      <el-tab-pane label="案例类" name="3"> </el-tab-pane>
      <el-tab-pane label="其他项目类" name="4"> </el-tab-pane>
    </el-tabs>

    <el-form :model="form" :inline="true">
      <el-form-item label="项目年份">
        <el-date-picker
          v-model="form.common_year"
          type="year"
          placeholder="选择年"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="对象类型">
        <el-select v-model="form.common_obj_type" clearable multiple>
          <el-option
            v-for="item in OBJ_TYPE_LIST"
            :key="'objt-type-' + item.value"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <download-excel
          :fetch="exportExcel"
          :name="excelName"
          :fields="excel_fields"
        >
          <el-button type="primary">导出</el-button>
        </download-excel>
      </el-form-item>
    </el-form>
    <el-table
      :data="tables"
      style="width: 100%"
      :border="true"
      height="500px"
      ref="table"
    >
      <template v-if="form.type + '' === '1'">
        <el-table-column
          key="1-project_code"
          prop="project_code"
          label="项目编号"
          width="180"
          align="center"
          fixed="left"
        >
        </el-table-column>
        <el-table-column prop="owner" label="产权人" width="180" align="center">
        </el-table-column>
        <el-table-column
          key="1-el_obj_type"
          prop="el_obj_type"
          label="评估对象类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="{ row }"
            >{{ formatEnum(row.el_obj_type, OBJ_TYPE_LIST) }}
          </template>
        </el-table-column>
        <el-table-column
          key="1-el_obj_address"
          prop="el_obj_address"
          label="评估对象位置"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          key="1-el_client"
          prop="el_client"
          label="委托方"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="el_org"
          key="1-el_org"
          label="评估单位"
          min-width="150"
          align="center"
        >
          <template slot-scope="{ row }"
            >{{ formatEnum(row.el_org, EL_ORG_LIST) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="el_time"
          key="1-el_time"
          label="评估时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
      </template>

      <template v-else-if="form.type + '' === '2'">
        <el-table-column
          key="2-court_book"
          prop="court_book"
          label="法院执行裁定书"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          key="2-owner"
          prop="owner"
          label="产权人"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          key="2-el_obj_type"
          prop="el_obj_type"
          label="评估对象类型"
          min-width="150"
          align="center"
        >
          <template slot-scope="{ row }"
            >{{ formatEnum(row.el_obj_type, OBJ_TYPE_LIST) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="au_obj_address"
          key="2-au_obj_address"
          label="拍卖对象位置"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="au_info_url"
          key="2-au_info_url"
          label="拍卖信息链接"
          min-width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          key="2-court_name"
          prop="court_name"
          label="法院名称"
          min-width="150"
          align="center"
        >
          <template slot-scope="{ row }"
            >{{ formatEnum(row.court_name, EL_COURT_LIST) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="au_time"
          key="2-au_time"
          label="拍卖成交时间"
          min-width="150"
          align="center"
        >
        </el-table-column>
      </template>

      <template v-else-if="form.type + '' === '3'">
        <el-table-column
          prop="ex_name"
          key="3-ex_name"
          label="案例名称"
          min-width="180"
          align="center"
        >
        </el-table-column>

        <el-table-column
          key="3-ex_obj_address"
          prop="ex_obj_address"
          label="案例对象位置"
          min-width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="案例对象类型"
          min-width="150"
          align="center"
          key="3-ex_obj_type"
        >
          <template slot-scope="{ row }"
            >{{ formatEnum(row.ex_obj_type, EX_TYPE_LIST) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="ex_time"
          key="3-ex_time"
          label="案例收集时间"
          min-width="180"
          align="center"
        >
        </el-table-column>
      </template>

      <template v-else-if="form.type + '' === '4'">
        <el-table-column
          prop="project_name"
          label="项目名称"
          key="4-project_name"
          min-width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="project_address"
          key="4-project_address"
          label="项目位置"
          min-width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          key="4-project_time"
          prop="project_time"
          label="项目时间"
          min-width="180"
          align="center"
        >
        </el-table-column>
      </template>

      <el-table-column
        prop="remark"
        :key="form.type + '-remark'"
        width="105px"
        label="备注"
        align="center"
      >
      </el-table-column>
      <el-table-column
        :key="form.type + '-files'"
        label="附件"
        align="center"
        width="100px"
      >
        <template slot-scope="{ row }">
          <el-popover
            :key="form.type + '-pop-item-' + row.id"
            placement="right"
            width="300"
            trigger="click"
            title="附件列表"
          >
            <div class="download">
              <div
                class="download-item"
                v-for="item in getFileList(row.files)"
                :key="'file-' + item.url"
              >
                <a :href="item.url" target="_blank"
                  ><i class="el-icon-document"></i> {{ item.name }}</a
                >
              </div>
            </div>
            <el-button
              v-if="row.files"
              size="mini"
              type="primary"
              slot="reference"
              icon="el-icon-document"
              >附件</el-button
            >
          </el-popover>

          <!-- <a
            target="_blank"
            v-for="file in getFileList(row.files)"
            :key="file.url"
            :href="file.url"
            >{{ file.name }}</a
          > -->
        </template>
      </el-table-column>
    </el-table>
    <template slot="footer">
      <el-pagination
        style="margin-top: 16px"
        background
        layout="prev, pager, next ,sizes,jumper"
        :total="total"
        :page-size.sync="page.pageSize"
        :page-sizes="[20, 50, 100]"
        :current-page="page.pageNo"
        :pager-count="11"
        @current-change="onPageChange"
        @size-change="loadPage()"
      >
      </el-pagination>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "PointExportDialog",
  data() {
    return {
      visable: false,
      form: {
        type: "1",
        common_year: undefined,
        common_obj_type: undefined,
        pageNo: 1,
        pageSize: 20,
      },
      tables: [],
      page: {
        pageSize: 20,
        pageNo: 1,
      },
      total: 0,
    };
  },
  computed: {
    excelName() {
      const data = this.POINT_TYPE_LIST.find(
        (item) => `${item.value}` === this.form.type
      );
      if (data) {
        return data.name;
      } else {
        return "数据";
      }
    },
    /**
     *
     */
    excel_fields() {
      switch (this.form.type) {
        case "1": {
          return {
            //表头设计
            经度: "longitude",
            纬度: "latitude",
            项目编号: "project_code",
            产权人: "owner",
            评估对象类型: {
              field: "el_obj_type",
              callback: (value) => {
                return this.formatEnum(value, this.OBJ_TYPE_LIST);
              },
            },
            评估对象位置: "el_obj_address",
            委托方: "el_client",
            评估单位: {
              field: "el_org",
              callback: (value) => {
                return this.formatEnum(value, this.EL_ORG_LIST);
              },
            },
            评估时间: "el_time",
            附件: {
              field: "files",
              callback: (value) => {
                return this.getFileList(value)
                  .map((item) => item.url)
                  .join("\n");
              },
            },
            备注: "remark",
          };
        }
        case "2": {
          return {
            //表头设计
            经度: "longitude",
            纬度: "latitude",
            法院执行裁定书: "court_book",
            产权人: "owner",
            评估对象类型: {
              field: "el_obj_type",
              callback: (value) => {
                return this.formatEnum(value, this.OBJ_TYPE_LIST);
              },
            },
            拍卖对象位置: "au_obj_address",
            拍卖信息链接: "au_info_url",
            法院名称: {
              field: "court_name",
              callback: (value) => {
                return this.formatEnum(value, this.EL_COURT_LIST);
              },
            },
            拍卖成交时间: "au_time",
            附件: {
              field: "files",
              callback: (value) => {
                return this.getFileList(value)
                  .map((item) => item.url)
                  .join("\n");
              },
            },
            备注: "remark",
          };
        }
        case "3": {
          return {
            //表头设计
            经度: "longitude",
            纬度: "latitude",
            案例名称: "ex_name",
            案例对象类型: {
              field: "ex_obj_type",
              callback: (value) => {
                return this.formatEnum(value, this.EX_TYPE_LIST);
              },
            },
            案例对象位置: "ex_obj_address",
            案例收集时间: "ex_time",
            附件: {
              field: "files",
              callback: (value) => {
                return this.getFileList(value)
                  .map((item) => item.url)
                  .join("\n");
              },
            },
            备注: "remark",
          };
        }
        case "4": {
          return {
            //表头设计
            经度: "longitude",
            纬度: "latitude",
            项目名称: "project_name",
            项目位置: "project_address",
            项目时间: "project_time",
            附件: {
              field: "files",
              callback: (value) => {
                return this.getFileList(value)
                  .map((item) => item.url)
                  .join("\n");
              },
            },
            备注: "remark",
          };
        }
        default: {
          return {};
        }
      }
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(val) {
      this.visable = val;
      if (val) {
        this.search();
      }
    },
    visable(val) {
      this.$emit("update:show", val);
    },
    "form.type"() {
      const type = this.form.type;
      this.reset();
      this.form.type = type;
      this.$nextTick(() => {
        this.$refs.table.doLayout();
        this.search();
      });
    },
  },
  methods: {
    reset() {
      this.form = {
        type: "1",
        common_year: undefined,
        common_obj_type: undefined,
      };
      this.page.pageNo = 1;
    },
    onPageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.loadPage();
    },
    async search() {
      this.page.pageNo = 1;
      this.loadPage();
    },
    async loadPage() {
      try {
        this.loading = true;
        const res = await this.$api.post("/api/project/list", {
          ...this.form,
          ...this.page,
        });
        this.tables = res.rows;
        this.total = res.count;
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    submit() {
      this.$emit("update:point", this.pointForm);
      this.visable = false;
    },
    async exportExcel(data) {
      console.log(data);
      const res = await this.$api.post("/api/project/list", {
        ...this.form,
        pageSize: 9999,
        pageNo: 1,
      });
      return res.rows;
    },
  },
};
</script>


<style scoped>
.download {
  border-bottom: 1px solid #efefef;
}
.download-item {
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  padding-top: 8px;
  padding-block: 8px;
  padding-left: 4px;
  padding-right: 4px;
}
</style>