<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="visable"
    @close="reset()"
    width="800px"
  >
    <el-form
      ref="form"
      :model="pointForm"
      title="新增项目点位"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="点位类型">
        <el-select
          v-model="pointForm.type"
          placeholder="请选择点位类型"
          :disabled="!!this.detail"
        >
          <el-option
            v-for="item in pointTypeList"
            :key="'point-item-' + item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 常规评估类 -->
      <template v-if="pointForm.type === 1">
        <el-form-item label="项目编号" prop="project_code">
          <el-input
            v-model="pointForm.project_code"
            placeholder="项目编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="产权人" prop="owner">
          <el-input v-model="pointForm.owner" placeholder="产权人"></el-input>
        </el-form-item>
        <el-form-item label="评估对象类型" prop="el_obj_type">
          <el-select v-model="pointForm.el_obj_type">
            <el-option
              v-for="item in OBJ_TYPE_LIST"
              :key="'obj-item-' + item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评估对象位置" prop="el_obj_address">
          <el-input
            v-model="pointForm.el_obj_address"
            placeholder="评估对象位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="委托方" prop="el_client">
          <el-select v-model="pointForm.el_client" :filterable="true"  :allow-create="true">
            <el-option
              v-for="item in EL_CLIENT"
              :key="'client-item-' + item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评估单位" prop="el_org">
          <el-select v-model="pointForm.el_org">
            <el-option
              v-for="item in elOrgList"
              :key="'obj-item-' + item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评估时间" prop="el_time">
          <el-date-picker v-model="pointForm.el_time"> </el-date-picker>
        </el-form-item>
      </template>

      <!-- 司法拍卖类 -->
      <template v-if="pointForm.type === 2">
        <el-form-item label="法院执行裁定书" prop="court_book">
          <el-input
            v-model="pointForm.court_book"
            placeholder="法院执行裁定书"
          ></el-input>
        </el-form-item>
        <el-form-item label="产权人" prop="owner">
          <el-input v-model="pointForm.owner" placeholder="产权人"></el-input>
        </el-form-item>
        <el-form-item label="评估对象类型" prop="el_obj_type">
          <el-select v-model="pointForm.el_obj_type">
            <el-option
              v-for="item in OBJ_TYPE_LIST"
              :key="'obj-item-' + item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拍卖对象位置" prop="au_obj_address">
          <el-input
            v-model="pointForm.au_obj_address"
            placeholder="拍卖对象位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="拍卖信息链接" prop="au_info_url">
          <el-input
            v-model="pointForm.au_info_url"
            placeholder="拍卖信息链接"
          ></el-input>
        </el-form-item>

        <el-form-item label="法院名称" prop="court_name">
          <el-select v-model="pointForm.court_name">
            <el-option
              v-for="item in EL_COURT_LIST"
              :key="'obj-court-item-' + item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拍卖成交时间" prop="au_time">
          <el-date-picker v-model="pointForm.au_time"> </el-date-picker>
        </el-form-item>
      </template>

      <!-- 案例类 -->
      <template v-if="pointForm.type === 3">
        <el-form-item label="案例名称" prop="ex_name">
          <el-input
            v-model="pointForm.ex_name"
            placeholder="案例名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="案例对象类型" prop="ex_obj_type">
          <el-select v-model="pointForm.ex_obj_type">
            <el-option
              v-for="item in EX_TYPE_LIST"
              :key="'obj-item-' + item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="案例对象位置" prop="ex_obj_address">
          <el-input
            v-model="pointForm.ex_obj_address"
            placeholder="拍卖对象位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="案例收集时间" prop="ex_time">
          <el-date-picker v-model="pointForm.ex_time"> </el-date-picker>
        </el-form-item>
      </template>

      <!-- 其他类 -->
      <template v-if="pointForm.type === 4">
        <el-form-item label="项目名称" prop="project_name">
          <el-input
            v-model="pointForm.project_name"
            placeholder="项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目位置" prop="project_address">
          <el-input
            v-model="pointForm.project_address"
            placeholder="项目位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目时间" prop="project_time">
          <el-date-picker v-model="pointForm.project_time"> </el-date-picker>
        </el-form-item>
      </template>

      <el-form-item label="附件" v-if="visable">
        <el-upload
          :action="action"
          multiple
          :on-change="onChangeFiles"
          :on-remove="onChangeFiles"
          :file-list.sync="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">支持上传任意格式的附件</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="备注">
        <el-input
          v-model="pointForm.remark"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
          placeholder="请输入内容"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="visable = false">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
const pointTemp = {
  type: 1,
  project_code: undefined,
  el_obj_type: undefined,
  el_obj_address: undefined,
  el_client: undefined,
  el_org: undefined,
  el_time: undefined,
  court_book: undefined,
  au_obj_address: undefined,
  au_info_url: undefined,
  ex_name: undefined,
};

export default {
  name: "PointUpsertDialog",
  data() {
    return {
      visable: false,
      action: process.env.VUE_APP_API_BASE_URL + "/api/file/upload",
      pointForm: JSON.parse(JSON.stringify(pointTemp)),
      fileList: [],
    };
  },
  computed: {
    pointTypeList() {
      switch (this.role.role_code) {
        case "admin": {
          return this.POINT_TYPE_LIST;
        }
        default: {
          return this.POINT_TYPE_LIST.filter((item) => item.value !== 4);
        }
      }
    },
    elOrgList() {
      switch (this.role.role_code) {
        case "admin": {
          return this.EL_ORG_LIST;
        }
        case "usera": {
          return this.EL_ORG_LIST.filter((item) => item.value === 2);
        }
        case "userb": {
          return this.EL_ORG_LIST.filter((item) => item.value === 3);
        }
        default: {
          return [];
        }
      }
    },
    title() {
      if (this.detail) {
        return "编辑项目点位";
      } else {
        return "新增项目点位";
      }
    },
    rules() {
      return {
        project_code: [
          { required: true, message: "请输入项目编号", trigger: "blur" },
        ],
        owner: [{ required: true, message: "请输入产权人", trigger: "blur" }],
        el_obj_type: [
          { required: true, message: "请选择评估对象类型", trigger: "change" },
        ],
        el_obj_address: [
          { required: true, message: "请输入评估对象位置", trigger: "blur" },
        ],
        el_client: [
          { required: true, message: "请选择委托方", trigger: "change" },
        ],
        el_org: [
          { required: true, message: "请选择评估单位", trigger: "change" },
        ],
        el_time: [
          { required: true, message: "请选择评估时间", trigger: "change" },
        ],

        court_book: [
          { required: true, message: "请输入法院执行裁定书", trigger: "blur" },
        ],
        au_obj_address: [
          { required: true, message: "请输入拍卖对象位置", trigger: "blur" },
        ],
        au_info_url: [
          { required: true, message: "请输入拍卖信息链接", trigger: "blur" },
        ],
        court_name: [
          { required: true, message: "请选择法院名称", trigger: "change" },
        ],
        au_time: [
          { required: true, message: "请选择拍卖成交时间", trigger: "change" },
        ],

        ex_name: [
          { required: true, message: "请输入案例名称", trigger: "blur" },
        ],
        ex_obj_type: [
          { required: true, message: "请选择案例对象类型", trigger: "change" },
        ],
        ex_obj_address: [
          { required: true, message: "请输入拍卖对象位置", trigger: "blur" },
        ],
        ex_time: [
          { required: true, message: "请选择案例收集时间", trigger: "change" },
        ],

        project_name: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        project_address: [
          { required: true, message: "请输入项目位置", trigger: "blur" },
        ],
        project_time: [
          { required: true, message: "请选择项目时间", trigger: "change" },
        ],
      };
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    point: {
      type: Object,
      default: () => {
        return null;
      },
    },
    detail: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  watch: {
    show(val) {
      this.visable = val;
    },
    visable(val) {
      this.$emit("update:show", val);
    },
    point(val) {
      this.pointForm = {
        ...this.pointForm,
        ...val,
      };
    },
    detail(val) {
      this.pointForm = {
        ...val,
      };
      if (this.pointForm.files) {
        let urls = this.pointForm.files.split(",") || [];
        urls = urls.filter((item) => !!item);
        this.fileList = urls.map((item) => {
          const l = item.split("/") || [];
          return {
            name: l[l.length - 1],
            url: item,
          };
        });
      }
    },
  },
  methods: {
    reset() {
      this.pointForm = JSON.parse(JSON.stringify(pointTemp));
      this.$refs.form.clearValidate();
      this.fileList = [];
    },
    onChangeFiles(file, fileList) {
      console.log(123);
      console.log(JSON.stringify(file));
      console.log(JSON.stringify(fileList));
      const list = fileList
        .filter((item) => {
          return (
            item.status === "success" &&
            (item.url ||
              (item.response && item.response.code === 0 && item.response.data))
          );
        })
        .map((item) => item.url || item.response.data);
      // const oldList = this.fileList.map((item) => item.url);
      this.pointForm.files = [...list].join(",");
    },
    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      try {
        if (this.pointForm.id) {
          await this.$api.post("/api/project/update", {
            ...this.pointForm,
          });
        } else {
          await this.$api.post("/api/project/create", {
            ...this.pointForm,
            longitude: this.point.lng,
            latitude: this.point.lat,
          });
        }
        this.$emit("onChange");
        this.visable = false;
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
};
</script>


<style>
</style>