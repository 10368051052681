<template>
  <el-dialog
    title="账号管理"
    :close-on-click-modal="false"
    :visible.sync="visable"
    @closed="reset()"
    width="800px"
  >
    <el-table :data="tables" style="width: 100%" :border="true" height="500px">
      <el-table-column
        prop="username"
        label="用户名"
        align="center"
        min-width="100"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="120">
        <template slot-scope="{ row }">
          <el-button
            icon="el-icon-refresh-left"
            type="text"
            @click="onClickReset(row)"
            >重置密码</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <template slot="footer">
      <el-pagination
        style="margin-top: 16px"
        background
        :page-sizes="[20, 50, 100]"
        layout="prev, pager, next,sizes,jumper"
        :total="total"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        :pager-count="11"
        @current-change="onPageChange"
        @size-change="loadPage()"
      >
      </el-pagination>
    </template>
  </el-dialog>
</template>
  
<script>
export default {
  name: "PointExportDialog",
  data() {
    return {
      visable: false,
      form: {
        type: "1",
        common_year: undefined,
        common_obj_type: undefined,
      },
      tables: [],
      page: {
        pageSize: 20,
        pageNo: 1,
      },
      total: 0,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(val) {
      this.visable = val;
      if (val) {
        this.search();
      }
    },
    visable(val) {
      this.$emit("update:show", val);
    },
  },
  methods: {
    reset() {
      this.form = {
        type: "1",
        el_year: undefined,
        el_obj_type: undefined,
        ex_obj_type: undefined,
      };
    },
    async search() {
      this.page.pageNo = 1;
      this.loadPage();
    },
    async loadPage() {
      try {
        this.loading = true;
        const res = await this.$api.post("/api/user/list", {
          ...this.form,
          ...this.page,
        });
        this.tables = res.rows;
        this.total = res.count;
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    async onClickReset(item) {
      try {
        await this.$confirm(`确认要重置账号${item.username}的密码？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } catch (error) {
        return;
      }
      try {
        await this.$api.post("/api/user/reset-pwd", {
          userId: item.id,
        });
        this.$message.success(`${item.username}的密码已重置`);
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    onPageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.loadPage();
    },
  },
};
</script>
  
  
  <style>
</style>