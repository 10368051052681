import axios from 'axios'
// import { Message } from 'element-ui'

// create an axios instance
const service = axios.create({
    // baseURL: "http://localhost:8000", // url = base url + request url
    baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 15000 // request timeout
})

// 添加请求拦截器
service.interceptors.request.use(function (request) {
    // 在发送请求之前做些什么
    return request;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    console.log(response.data)
    if (response.data.code === 0) {
        return response.data.data
    } else {
        throw {
            ...response.data,
            message: response.data ? response.data.msg : ""
        };
    }
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject({
        code: -1,
        msg: error.message,
        message: error.message,
        error: error
    });
});
export default service
