<template>
  <el-dialog :visible.sync="visable" title="修改密码" width="600px" :close-on-click-modal="false">
    <el-form label-width="100px">
      <el-form-item label="旧密码">
        <el-input type="password" v-model="form.oldPwd"></el-input>
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" v-model="form.newPwd"></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input type="password" v-model="form.confirmPwd"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="visable = false">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
const modifyPwdFormTemp = {
  oldPwd: "",
  newPwd: "",
  confirmPwd: "",
};
export default {
  data() {
    return {
      visable: false,
      form: JSON.parse(JSON.stringify(modifyPwdFormTemp)),
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(val) {
      this.visable = val;
      if (val) {
        this.form = JSON.parse(JSON.stringify(modifyPwdFormTemp));
      }
    },
    visable(val) {
      this.$emit("update:show", val);
    },
  },
  methods: {
    async submit() {
      if (this.form.newPwd !== this.form.confirmPwd) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      if (this.form.newPwd.length < 6) {
        this.$message.error("密码至少六位");
        return;
      }
      try {
        this.loading = true;
        await this.$api.post("/api/user/modify-pwd", {
          password_old: this.form.oldPwd,
          password_new: this.form.newPwd,
        });
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        await this.$store.dispatch("session");
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>