import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, form, next) => {
  if (to.path !== '/login') {
    try {
      const { user, role } = await Vue.prototype.$api.post("/api/user/session")
      Vue.prototype.$store.commit("setUser", user);
      Vue.prototype.$store.commit("setRole", role);
      next();
    } catch (error) {
      if (error.code === 1) {
        next("/login")
        return;
      } else {
        next();
      }
    }
  } else {
    next();
  }
})

export default router
