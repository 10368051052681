<template>
  <div>
    <el-dialog
      title="项目信息"
      @open="onOpen"
      :visible.sync="visable"
      :close-on-click-modal="false"
      width="800px"
      v-loading="true"
    >
      <el-descriptions
        title="用户信息"
        :column="1"
        v-if="detail"
        size="medium"
        labelClassName="des-label"
      >
        <el-descriptions-item label="点位类型"
          >{{ formatEnum(detail.type, POINT_TYPE_LIST) }}
        </el-descriptions-item>
        <!-- 常规评估类 -->
        <template v-if="detail.type === 1">
          <el-descriptions-item label="项目编号"
            >{{ detail.project_code }}
          </el-descriptions-item>
          <el-descriptions-item label="产权人"
            >{{ detail.owner }}
          </el-descriptions-item>
          <el-descriptions-item label="评估对象类型"
            >{{ formatEnum(detail.el_obj_type, OBJ_TYPE_LIST) }}
          </el-descriptions-item>
          <el-descriptions-item label="评估对象位置"
            >{{ detail.el_obj_address }}
          </el-descriptions-item>
          <el-descriptions-item label="委托方"
            >{{ formatEnum(detail.el_client, EL_CLIENT) }}
          </el-descriptions-item>
          <el-descriptions-item label="评估单位"
            >{{ formatEnum(detail.el_org, EL_ORG_LIST) }}
          </el-descriptions-item>
          <el-descriptions-item label="评估时间"
            >{{ formatData(detail.el_time, "YYYY年MM月DD日") }}
          </el-descriptions-item>
        </template>

        <!-- 司法拍卖类 -->
        <template v-if="detail.type === 2">
          <el-descriptions-item label="法院执行裁定书"
            >{{ detail.court_book }}
          </el-descriptions-item>
          <el-descriptions-item label="产权人"
            >{{ detail.owner }}
          </el-descriptions-item>
          <el-descriptions-item label="评估对象类型"
            >{{ formatEnum(detail.el_obj_type, OBJ_TYPE_LIST) }}
          </el-descriptions-item>
          <el-descriptions-item label="拍卖对象位置"
            >{{ detail.au_obj_address }}
          </el-descriptions-item>
          <el-descriptions-item label="拍卖信息链接"
            >{{ detail.au_info_url }}
          </el-descriptions-item>
          <el-descriptions-item label="法院名称"
            >{{ formatEnum(detail.court_name, EL_COURT_LIST) }}
          </el-descriptions-item>
          <el-descriptions-item label="拍卖成交时间"
            >{{ formatData(detail.au_time, "YYYY年MM月DD日") }}
          </el-descriptions-item>
        </template>

        <!-- 案例类 -->
        <template v-if="detail.type === 3">
          <el-descriptions-item label="案例名称"
            >{{ detail.ex_name }}
          </el-descriptions-item>
          <el-descriptions-item label="案例对象类型"
            >{{ formatEnum(detail.ex_obj_type, OBJ_TYPE_LIST) }}
          </el-descriptions-item>
          <el-descriptions-item label="案例对象位置"
            >{{ detail.ex_obj_address }}
          </el-descriptions-item>
          <el-descriptions-item label="案例收集时间"
            >{{ formatData(detail.ex_time, "YYYY年MM月DD日") }}
          </el-descriptions-item>
        </template>

        <!-- 案例类 -->
        <template v-if="detail.type === 4">
          <el-descriptions-item label="项目名称"
            >{{ detail.project_name }}
          </el-descriptions-item>
          <el-descriptions-item label="项目位置"
            >{{ detail.project_address }}
          </el-descriptions-item>
          <el-descriptions-item label="项目时间"
            >{{ formatData(detail.project_time, "YYYY年MM月DD日") }}
          </el-descriptions-item>
        </template>

        <!-- <el-form-item label="附件">
          <el-upload
            action="http://[::1]:8000/api/file/upload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="3"
            :on-exceed="handleExceed"
            :on-change="onChangeFiles"
            :file-list.sync="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item> -->
        <el-descriptions-item label="附件">
          <div>
            <div v-for="item in fileList" :key="'file-' + item.url">
              <a :href="item.url" target="_blank"
                ><i class="el-icon-document"></i> {{ item.name }}</a
              >
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="备注"
          >{{ detail.remark }}
        </el-descriptions-item>
      </el-descriptions>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onClickEdit()" v-if="showEdit"
          >编辑</el-button
        >
        <el-button type="danger" @click="onClickRemove()" v-if="showRemove"
          >删除</el-button
        >
      </div>
    </el-dialog>
    <point-upsert-dialog
      @onChange="onChange()"
      :show.sync="visableEdit"
      :detail="detailForm"
    ></point-upsert-dialog>
  </div>
</template>

<script>
import PointUpsertDialog from "./PointUpsertDialog.vue";
import {
  POINT_TYPE_LIST,
  OBJ_TYPE_LIST,
  EL_ORG_LIST,
  EL_CLIENT,
} from "@/config/enum";

export default {
  name: "PointDetailDialog",
  components: { PointUpsertDialog },
  data() {
    return {
      visableEdit: false,
      visable: false,
      fileList: [],
      detailForm: undefined,
      POINT_TYPE_LIST,
      OBJ_TYPE_LIST,
      EL_ORG_LIST,
      EL_CLIENT,
    };
  },
  computed: {
    showEdit() {
      if (!this.role) return false;
      if (!this.detail) return false;
      switch (this.role.role_code) {
        case "admin": {
          return true;
        }
        case "usera":
        case "userb": {
          return (
            (this.detail.type === 1 && this.detail.el_org === this.role.id) ||
            (this.detail.type === 2 &&
              this.detail.create_role === this.role.id) ||
            (this.detail.type === 3 && this.detail.create_role === this.role.id)
          );
        }
        default: {
          return false;
        }
      }
    },
    showRemove() {
      if (!this.role) return false;
      if (!this.detail) return false;
      switch (this.role.role_code) {
        case "admin": {
          return true;
        }
        case "usera":
        case "userb": {
          return (
            (this.detail.type === 1 && this.detail.el_org === this.role.id) ||
            (this.detail.type === 2 &&
              this.detail.create_role === this.role.id) ||
            (this.detail.type === 3 && this.detail.create_role === this.role.id)
          );
        }
        default: {
          return false;
        }
      }
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  watch: {
    show(val) {
      this.visable = val;
    },
    visable(val) {
      this.$emit("update:show", val);
    },
    detail(val) {
      if (val) {
        if (val.files) {
          this.fileList = this.getFileList(val.files);
        } else {
          this.fileList = [];
        }
      } else {
        this.fileList = [];
      }
    },
  },
  methods: {
    reset() {
      this.pointForm = {};
    },
    onClickEdit() {
      this.detailForm = this.detail;
      this.visableEdit = true;
      this.visable = false;
    },
    onOpen() {
      this.detailForm = null;
    },
    onChange() {
      this.$emit("onChange");
    },
    async onClickRemove() {
      try {
        await this.$confirm("确认删除项目信息？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } catch (error) {
        return;
      }
      try {
        await this.$api.post("/api/project/remove", {
          id: this.detail.id,
        });
        this.$emit("onChange");
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        this.visable = false;
      }
    },
  },
};
</script>


<style >
.des-label {
  width: 120px;
}
</style>