import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        role: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setRole(state, role) {
            state.role = role;
        }
    },
    actions: {
        async session(context) {
            try {
                const { user, role } = await Vue.prototype.$api.post("/api/user/session")
                context.commit('setUser', user)
                context.commit('setRole', role)
            } catch (error) {
                if (error.code === 1) {
                    router.replace("/login")
                }
            }

        }
    }
})